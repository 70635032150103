(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name neo.services.auth.factory:PasswordToken
   *
   * @description
   *
   */
  angular
  .module('neo.services.auth')
  .factory('PasswordToken', PasswordToken);

  function PasswordToken($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/auth/passwordToken/:token', {token: '@_token'});
  }
}());
